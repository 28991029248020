<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeInProcess }"
        exact
        :exact-active-class="statusApp == 1 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
      >
        IN PROCESS
        <span v-if="G_PENDING_POTENCIAL > 0" class="ml-2">
          <feather-icon
            icon
            :badge="G_PENDING_POTENCIAL > 99 ? '+99' : G_PENDING_POTENCIAL"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeFinished }"
        exact
        :exact-active-class="statusApp == 2 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isInFinishedTab"
      >
        FINISHED
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" :program_id="programId" />
    </b-card>
  </div>
</template>
<script>
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";


export default {
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
  name: "PotencialMain",
  computed: {
    ...mapGetters({
      G_PENDING_POTENCIAL: "PotentialStore/G_PENDING_POTENCIAL",
      currentUser: "auth/currentUser",
    }),
    programId() {
      return this.$route.matched[1].meta.program;
    },
    isInFinishedTab() {
      const { routeCompleted, routeRejected, routeExpired } =
        this.$route.matched[1].meta;
      return [routeCompleted, routeRejected, routeExpired].includes(
        this.$route.name
      );
    },
    statusApp() {
      return this.$route.meta.statusApp;
    },
  },
  async created() {
    await this.refreshCountInTab();
  },
  methods: {
    ...mapMutations({
      M_PENDING_POTENCIAL: "PotentialStore/M_PENDING_POTENCIAL",
    }),
    ...mapActions({
      A_GET_PENDING_POTENTIAL: "PotentialStore/A_GET_PENDING_POTENTIAL",
    }),
    async refreshCountInTab() {
      await this.A_GET_PENDING_POTENTIAL();
    },
  },
};
</script>
